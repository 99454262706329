import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { measurement } from "../../../../constants";
import { getVehicleTripsList } from "../../../../redux/reducers/DataReducer/data.actions";
import {
  endLoading,
  startLoading,
} from "../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../redux/store/store";
import { LayoutContext } from "../../../../context/LayoutContext";
import { Link } from "react-router-dom";
import usePermission from "../../../../constants/usePermission";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { calculateTotalDrivenTime, convertDateToTimestamp, convertKilometerToMile, convertTimestampToDate, encrypt } from "../../../../services/common.functions.services";
import CachedIcon from "@material-ui/icons/Cached";
import { toast } from "react-toastify";
import { tripRecalculate } from "../../../../api/axios.resources";
import DataTable from "../../../Common/DataTable/dataTable";

interface TripsProps {
  endDate: $TSFixMe;
  startDate: $TSFixMe;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & TripsProps;

const Trips = (props: Props) => {
  const { selectedProject }: $TSFixMe = useContext(LayoutContext);
  const [tripsList, setTripsList]: $TSFixMe = useState([]);
  const [recordsPerPage, setRecordsPerPage]: $TSFixMe = useState(10);
  const [selectedPage, setSelectedPage]: $TSFixMe = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isAsc, setIsAsc]: $TSFixMe = useState(false);
  const [sortColumn, setSortColumn]: $TSFixMe = useState("");

  const isVehicleTripWrite = usePermission("VEHICLE_TRIP_WRITE");

  const handleTripRecalculate = async (id: number) => {
    try {
      let data = {
        asc: isAsc,
        column: sortColumn,
        projectId: selectedProject.id,
        page: {
          no: selectedPage,
          size: recordsPerPage
        },
        endTimestamp: props.endDate ? convertDateToTimestamp(props.endDate, props?.user?.timeZone?.zoneId) : null,
        startTimestamp: props.startDate ? convertDateToTimestamp(props.startDate, props?.user?.timeZone?.zoneId) : null
      };
      if (id && data.projectId) {
        const res = await tripRecalculate(data, id);
        if (res.status === 200) {
          apiCalls();
          toast.success(`Trip Recalculate Successfully`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const VehicleTripActionBtns = (data: $TSFixMe) => {
    const encryptedParams: $TSFixMe = encrypt({
      proj: selectedProject.id,
      id: data?.data?._id,
      hEvent: data?.data?.highEventCount,
      lEvent: data?.data?.lowEventCount,
      end: data?.data?.endTime,
      start: data?.data?.startTime,
      vehicleRegNo: data?.data?.deviceId,
    });
    return (
      <Link to={`/trip-details/${encryptedParams}`} className="tabLink">
        <DirectionsCarIcon className="dropico" />
      </Link>
    );
  };
  const VehicleTripActionBtns2 = (data: $TSFixMe) => {
    return (
      <DirectionsCarIcon className="tripActionBtns2 dropico" />
    );
  };

  const vehicleTripColumns = [
    { title: "Vehicle Reg#", data: "vehicleRegNo" },
    {
      title: "View Trip",
      format: (data: $TSFixMe) => data && data.status ? <VehicleTripActionBtns data={data} /> : <VehicleTripActionBtns2 />,
    },

    { title: "Driver", data: "driverName", sorting: false, format: (data: $TSFixMe) => data?.driverName },
    {
      title: "Start Time",
      data: "startTime",
      format: (data: $TSFixMe) => data.startTime ? convertTimestampToDate(props.user.timeZone.zoneId, data.startTime, null, props.user.timeFormat) : "N/A",
    },
    {
      title: "End Time",
      data: "endTime",
      format: (data: $TSFixMe) => data.endTime ? convertTimestampToDate(props.user.timeZone.zoneId, data.endTime, null, props.user.timeFormat) : "N/A",
    },
    {
      title: `Distance(${props.user.measurmentUnit === measurement[1].value ? "ml" : "km"}.)`,
      format: (data: $TSFixMe) => data.totalDistance ? props.user.measurmentUnit === measurement[1].value ? convertKilometerToMile(data.totalDistance) : data?.totalDistance?.toFixed(2) : "N/A",
    },
    { title: "Driven Time(s)", format: (data: $TSFixMe) => data.startTime && data.endTime ? calculateTotalDrivenTime(data.startTime, data.endTime) : "" },
    { title: "Idle Time", data: "idealTime" },
    { title: "High Events", data: "highEventCount" },
    { title: "Low Events", data: "lowEventCount" },
  ];

  if (isVehicleTripWrite) {
    vehicleTripColumns.splice(2, 0, {
      title: "Action",
      format: (data: $TSFixMe) => data.status && data?.totalDistance && data?.startTime && data?.endTime ? "N/A" : <CachedIcon className="dropico" onClick={() => handleTripRecalculate(data._id)} />,
    },);
  }

  const apiCalls = async () => {
    try {
      props.startLoading();
      let data = {
        asc: isAsc,
        column: sortColumn,
        projectId: selectedProject.id,
        page: {
          no: selectedPage,
          size: recordsPerPage
        },
        endTimestamp: props.endDate ? convertDateToTimestamp(props.endDate, props?.user?.timeZone?.zoneId) : null,
        startTimestamp: props.startDate ? convertDateToTimestamp(props.startDate, props?.user?.timeZone?.zoneId) : null
      };
      await props.getVehicleTripsList(data);
    } catch (error) {
      console.log("error in VehicleTripTab", error);
    } finally {
      setIsPageLoading(false);
      await props.endLoading();
    }
  };

  useEffect(() => {
    if ((selectedProject && Object.keys(selectedProject).length > 0) && props.user.userId !== 0) {
      apiCalls();
    }
    return () => { };
  }, [recordsPerPage, selectedPage, isAsc, sortColumn, selectedProject, props.user.userId, props.startDate]);

  useEffect(() => {
    setTripsList([...(props.vehicleTrips?.result?.data ?? [])]);
    return () => { };
  }, [props.vehicleTrips]);

  return (
    <div className="mt-3">
      <DataTable
        data={tripsList}
        columns={vehicleTripColumns}
        bordered
        pageData={props.vehicleTrips?.result?.page}
        totalRecrods={props.vehicleTrips?.result?.totalRecrods}
        setPageSize={setRecordsPerPage}
        pageSize={recordsPerPage}
        setCurrentPage={setSelectedPage}
        setSortColumn={setSortColumn}
        setIsAsc={setIsAsc}
        currentPage={selectedPage}
        hover={true}
        responsive={true}
        isPageLoading={isPageLoading}
        setIsPageLoading={setIsPageLoading}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  vehicleTrips: state.dataStore.vehicleTripData,
  user: state.userSlice.user,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getVehicleTripsList: (data: $TSFixMe) => dispatch(getVehicleTripsList(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Trips);
